<template>
  <div>
    <v-sheet class="pa-3" v-if="skeleton" :loading="skeleton">
      <v-skeleton-loader class="mx-auto" type="table"></v-skeleton-loader>
    </v-sheet>
    <div v-else>
      <v-container>
        <h1 class="mb-5">List e-Ticket</h1>
        <v-btn class="mb-5" color="primary" @click="dialogCreate = true">Tambah e-Ticket</v-btn>
        <v-form ref="formSearch" @submit.prevent="searchEtickets()">
          <v-row>
            <v-col cols="8">
              <v-text-field :loading="loading" v-model="formSearch.keyword" label="Masukkan Kode atau Token e-Ticket"></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-btn type="submit" color="primary" :loading="loading">Cari</v-btn>
            </v-col>
          </v-row>
        </v-form>
        <v-alert type="warning" v-if="errors.length > 0">
          <ul>
            <li v-for="error in errors" :key="error">{{ error }}</li>
          </ul>
        </v-alert>
        <v-card :loading="loading">
          <v-card-text>
            <v-simple-table>
              <thead>
                <tr>
                  <th>Code</th>
                  <th>Status</th>
                  <th>Nama Penerima</th>
                  <th>Email Penerima</th>
                  <th>Kadaluarsa</th>
                  <th class="text-center">Aksi <br> <small>Detail | Edit | Gunakan | Copy Link</small></th>
                </tr>
              </thead>
              <tbody>
                <i  v-if="etickets.data.length < 1">e-Ticket tidak ada</i>
                <tr v-else v-for="(eticket, i) in etickets.data" :key="i">
                  <td>{{ eticket.code }}</td>
                  <td v-if="eticket.used == '1' || eticket.used == 1 || eticket.used == true"><v-chip color="blue-grey" small dark>Digunakan</v-chip></td>
                  <td v-else><v-chip color="light-blue" small dark>Belum Digunakan</v-chip></td>
                  <td>{{ eticket.name ? eticket.name : '-' }}</td>
                  <td>{{ eticket.email ? eticket.email : '-' }}</td>
                  <td>{{ moment(eticket.expire).locale('id').format('LL') }}</td>
                  <td class="text-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn fab dark x-small color="blue" class="mx-2" v-bind="attrs" v-on="on" @click="showEticketDialog(eticket)"><v-icon>visibility</v-icon></v-btn>
                      </template>
                      <span>Detail</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="eticket.used == '0' || eticket.used == 0 || eticket.used == false">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn fab dark x-small color="green" class="mx-2" v-bind="attrs" v-on="on" @click="editEticketDialog(eticket)"><v-icon>edit</v-icon></v-btn>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="eticket.used == '0' || eticket.used == 0 || eticket.used == false && moment(eticket.expire).isBefore(moment(new Date.now()), 'date')">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn fab dark x-small color="yellow accent-4" class="mx-2" v-bind="attrs" v-on="on" @click="useEticketDialog(eticket)"><v-icon>task_alt</v-icon></v-btn>
                      </template>
                      <span>Gunakan Tiket</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="eticket.used == '0' || eticket.used == 0 || eticket.used == false && moment(eticket.expire).isBefore(moment(new Date.now()), 'date')">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn fab dark x-small color="purple" class="mx-2" v-bind="attrs" v-on="on" @click="copyLink(eticket.token)"><v-icon>content_copy</v-icon></v-btn>
                      </template>
                      <span>Copy Link e-Ticket</span>
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
            <div class="mt-4">
              <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css" integrity="sha384-B0vP5xmATw1+K9KRQjQERJvTumQW0nPEzvF6L/Z6nronJ3oUOFUFpCjEUQouq2+l" crossorigin="anonymous" />
              <pagination :data="etickets" @pagination-change-page="pagination == 'index' ? getEtickets() : searchEtickets() " align="right"></pagination>
            </div>
          </v-card-text>
        </v-card>
      </v-container>
      <v-dialog v-model="dialogEdit" width="500px" max-width="95%" :loading="loading">
        <v-card v-if="formUpdate">
          <v-card-title>Edit e-Ticket</v-card-title>
          <v-form ref="formUpdate" @submit.prevent="updateEticket()">
            <v-card-text>
              <v-alert type="warning" v-if="updateErrors.length > 0">
                <ul>
                  <li v-for="error in updateErrors" :key="error">{{ error }}</li>
                </ul>
              </v-alert>
              <v-text-field v-model="formUpdate.qty" label="Jumlah Tiket" :rules="rules.required"></v-text-field>
              <p>Tanggal Kadaluarsa Tiket</p>
              <v-date-picker :rules="rules.required" v-model="formUpdate.expire" locale="id" :min="date"></v-date-picker>
              <p>Status : {{ formUpdate.used == '1' || formUpdate.used == 1 || formUpdate.used == true ? 'Sudah Digunakan' : 'Belum Digunakan' }}</p>
              <v-text-field v-model="formUpdate.name" label="Nama Penerima (opsional)"></v-text-field>
              <v-text-field v-model="formUpdate.email" label="Email Penerima (opsional)"></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-btn type="submit" color="primary">Update</v-btn>
              <v-btn color="grey lighten-2" @click="dialogEdit = false">Batal</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogShow" width="900px" max-width="95%" :loading="loading">
        <v-card v-if="formShow">
          <v-card-title>Detail e-Ticket</v-card-title>
            <v-card-text>
              <v-simple-table>
                <tbody>

                <tr>
                  <th>Code</th>
                  <td>:</td>
                  <td>{{ formShow.code }}</td>
                </tr>
                <tr>
                  <th>Token</th>
                  <td>:</td>
                  <td>
                    <qr-code :text="formShow.token"></qr-code>
                    <br>
                    <span>{{ formShow.token }}</span>
                  </td>
                </tr>
                <tr>
                  <th>Status</th>
                  <td>:</td>
                  <td v-if="formShow.used == '1' || formShow.used == 1 || formShow.used == true"><v-chip color="blue-grey" small dark>Digunakan</v-chip></td>
                  <td v-else><v-chip color="light-blue" small dark>Belum Digunakan</v-chip></td>
                </tr>
                <tr>
                  <th>Nama Penerima</th>
                  <td>:</td>
                  <td>{{ formShow.name }}</td>
                </tr>
                <tr>
                  <th>Email Penerima</th>
                  <td>:</td>
                  <td>{{ formShow.email }}</td>
                </tr>
                <tr>
                  <th>Kadaluarsa</th>
                  <td>:</td>
                  <td>{{ moment(formShow.expire).locale('id').format('LL') }}</td>
                </tr>
                <tr>
                  <th>Digunakan Pada Tanggal</th>
                  <td>:</td>
                  <td>{{ moment(formShow.updated).locale('id').format('LLL') }}</td>
                </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
            <v-card-actions>
              <v-btn color="grey lighten-2" @click="dialogShow = false">Kembali</v-btn>
            </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogCreate" width="500px" max-width="95%" persistent>
        <v-card v-if="formCreate" :loading="loading"  :disabled="loading">
          <v-card-title>Tambah e-Ticket Baru</v-card-title>
          <v-form ref="formCreate" @submit.prevent="storeEticket()">
            <v-card-text>
              <v-alert type="warning" v-if="storeErrors.length > 0">
                <ul>
                  <li v-for="error in storeErrors" :key="error">{{ error }}</li>
                </ul>
              </v-alert>
              <p>Tanggal Kadaluarsa Tiket</p>
              <v-date-picker :rules="rules.required" v-model="formCreate.expire" locale="id" :min="date"></v-date-picker>
              <v-text-field v-model="formCreate.qty" label="Jumlah Tiket" :rules="rules.required"></v-text-field>
              <v-text-field v-model="formCreate.name" label="Nama Penerima"></v-text-field>
              <v-text-field v-model="formCreate.phone" label="Nomor HP Penerima"></v-text-field>
              <v-text-field v-model="formCreate.email" label="Email Penerima (opsional)"></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-btn type="submit" color="primary">Simpan</v-btn>
              <v-btn color="grey lighten-2" @click="dialogCreate = false">Batal</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogUse" width="500px" max-width="95%">
        <v-card v-if="formUse" :loading="loading"  :disabled="loading">
          <v-card-title>Gunakan e-Ticket : {{ formUse.code }}</v-card-title>
            <v-card-text>
              <v-alert type="warning" v-if="useErrors.length > 0">
                <ul>
                  <li v-for="error in useErrors" :key="error">{{ error }}</li>
                </ul>
              </v-alert>
            </v-card-text>
            <v-card-actions class="mt-5">
              <v-btn color="green" dark @click="useEticket()">Gunakan Tiket</v-btn>
              <v-btn color="grey lighten-2" @click="dialogUse = false">Batal</v-btn>
            </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import qrcode from 'vue-qrcode-component'

export default {
  name: 'eticket.index',
  metaInfo: { title: 'Dashboard' },
  components: { 'qr-code': qrcode },
  data(){
    return {
      skeleton: true,
      loading: false,
      dialogCreate: false,
      dialogShow: false,
      dialogEdit: false,
      dialogUse: false,
      etickets: null,
      pagination: 'index',
      moment,
      formCreate: {},
      formUpdate: {},
      formUse: {},
      formSearch: {},
      formShow: {},
      errors: [],
      storeErrors: [],
      updateErrors: [],
      useErrors: [],
      messages: [],
      date: null,
      rules: {
        required: [
          (v) => !!v || "Harus diisi",
        ],
      },
    }
  },
  computed: {
    token() {
      return this.$store.state.auth.token;
    },
    config() {
      return {
        header: {
          Authorization: "Bearer " + this.token,
        },
        crossDomain: true,
      };
    },
  },
  methods: {
    async getEtickets(page = 1){
      this.loading = true
      this.pagination = 'index'
      await axios.get(`/eticket?page=${page}`, this.config).then(res => {
        this.etickets = res.data.data.etickets
      }).catch(e => {
        if(e.response){
          if(e.response.status === 404){
            if(e.response.data.errors){
              this.errors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }else if(e.response.status === 422){
            this.errors = e.response.data.errors
          }else{
            this.errors = ['Error, something wrong :(']
            console.log(e);
          }
        }else{
          this.errors = ['Error, something wrong :(']
          console.log(e);
        }
      })
      window.scrollTo(0, 0);
      this.loading = false
      this.skeleton = false
    },
    async searchEtickets(page = 1){
      this.loading = true
      if(!this.formSearch.keyword){
        this.getEtickets()
      }else{
        this.pagination = 'search'
        await axios.get(`/eticket/search/${this.formSearch.keyword}?page=${page}`, this.config).then(res => {
          this.etickets = res.data.data.etickets
        }).catch(e => {
          if(e.response){
            if(e.response.status === 404){
              if(e.response.data.errors){
                this.errors = e.response.data.errors
              }else{
                this.errors = ['Error, something wrong :(']
                console.log(e);
              }
            }else if(e.response.status === 422){
              this.errors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }else{
            this.errors = ['Error, something wrong :(']
            console.log(e);
          }
        })
        window.scrollTo(0, 0);
        this.loading = false
        this.skeleton = false
      }
    },
    async storeEticket(){
      this.loading = true
      this.storeErrors = []
      await axios.post('/eticket', this.formCreate, this.config).then(res => {
        this.$toast.success('e-Ticket berhasil disimpan!')
        this.dialogCreate = false
        this.$refs.formCreate.reset()
        this.$refs.formCreate.resetValidation()
        this.getEtickets()
      }).catch(e => {
        if(e.response){
          if(e.response.status === 404){
            if(e.response.data.errors){
              this.errors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }else if(e.response.status === 422){
            this.storeErrors = e.response.data.errors
          }else{
            this.errors = ['Error, something wrong :(']
            console.log(e);
          }
        }else{
          this.errors = ['Error, something wrong :(']
          console.log(e);
        }
      })
      this.loading = false
    },
    async updateEticket(){
      this.loading = true
      this.updateErrors = []
      await axios.put(`/eticket/${this.formUpdate.token}`, this.formUpdate, this.config).then(res => {
        this.$toast.success('e-Ticket berhasil diupdate!')
        this.dialogEdit = false
        this.$refs.formUpdate.reset()
        this.$refs.formUpdate.resetValidation()
        this.getEtickets()
      }).catch(e => {
        if(e.response){
          if(e.response.status === 404){
            if(e.response.data.errors){
              this.updateErrors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }else if(e.response.status === 422){
            this.updateErrors = e.response.data.errors
          }else{
            this.errors = ['Error, something wrong :(']
            console.log(e);
          }
        }else{
          this.errors = ['Error, something wrong :(']
          console.log(e);
        }
      })
      window.scrollTo(0, 0);
      this.loading = false
    },
    async useEticket(){
      this.loading = true
      this.updateErrors = []
      console.log(this.formUse);
      await axios.put(`/eticket/use/${this.formUse.token}`, this.formUse, this.config).then(res => {
        this.$toast.success('e-Ticket berhasil digunakan!')
        this.dialogUse = false
        this.formUse = {}
        this.getEtickets()
      }).catch(e => {
        if(e.response){
          if(e.response.status === 404){
            if(e.response.data.errors){
              this.updateErrors = e.response.data.errors
            }else{
              this.errors = ['Error, something wrong :(']
              console.log(e);
            }
          }else if(e.response.status === 422){
            this.updateErrors = e.response.data.errors
          }else{
            this.errors = ['Error, something wrong :(']
            console.log(e);
          }
        }else{
          this.errors = ['Error, something wrong :(']
          console.log(e);
        }
      })
      window.scrollTo(0, 0);
      this.loading = false
    },
    editEticketDialog(eticket){
      this.formUpdate = {...eticket}
      this.dialogEdit = true
    },
    showEticketDialog(eticket){
      this.formShow = {...eticket}
      this.dialogShow = true
    },
    useEticketDialog(eticket){
      this.formUse = {...eticket}
      this.dialogUse = true
    },
    generateDate(){
      const date = new Date()
      this.date = this.moment(date).format('YYYY-MM-DD')
    },
    copyLink(token){
      navigator.clipboard.writeText('https://eticket.garutdinoland.com/' + token)
      this.$toast.success('Berhasil copy link e-Ticket!')
    }
  },
  created(){
    this.getEtickets()
    this.generateDate()
    console.log(moment('2010-10-22').isBefore(moment('2010-10-21'), 'date'))
    
  }
}
</script>

<style>

</style>